/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { action } from '@ember/object';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class PracticeWebsiteHeaderDetails extends Component {
  @service websiteWidget;
  @service currentPractice;

  @reads('openContactWidgetTask.isRunning') isLoading;

  get featurePracticeContactForm() {
    return this.currentPractice.get('featurePracticeContactForm');
  }

  @(task(function* () {
    let previewModel = this.preview ? this.model : undefined;
    yield this.websiteWidget.revealTask.perform(previewModel, 'contact');
    yield timeout(400); // widget fade in animation delay
  }).drop())
  openContactWidgetTask;

  @action
  onContactClick() {
    this.featurePracticeContactForm
      ? this.openContactWidgetTask.perform()
      : this.toggleContactModal();
  }
}
