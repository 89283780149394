import 'client-portal/integration';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { reads } from '@ember/object/computed';
import { task, timeout } from 'ember-concurrency';
import ENV from 'client-portal/config/environment';
import Service, { service } from '@ember/service';
import classic from 'ember-classic-decorator';

export const WEBSITE_WIDGET_CHANNEL = 'sp_website';
const REVEAL_ANIMATION_TIMEOUT_MS = 300;

@classic
export default class WebsiteWidgetService extends Service {
  @service currentPractice;

  revealAnimationTimeout = REVEAL_ANIMATION_TIMEOUT_MS;

  @reads('currentPractice.schedulingWidgetApplicationId') applicationId;
  @reads('currentPractice.schedulingWidgetScopeId') scopeId;
  @reads('currentPractice.schedulingWidgetUrl') widgetsUrl;

  init() {
    super.init(...arguments);
    this.widgetInstances = {};
  }

  // NOTE: for website editor preview mode, we manually pass theme attributes in previewModel
  initWidget(previewModel, type) {
    let { applicationId, widgetsUrl, scopeId } = this;

    if (!(applicationId && widgetsUrl && scopeId)) {
      return;
    }

    let nodeId = `${type}-widget-${scopeId}`;
    let node = document.getElementById(nodeId);

    // NOTE: to update preview theme we remove previous widget & reinitialize
    if (previewModel) {
      node?.remove();
    }

    let widget = this._retrieveWidget(scopeId);

    if (node && widget) {
      return widget;
    }

    // NOTE: widget exists but was removed from the DOM - re-append it
    if (widget) {
      this._appendWidget(widget);
      return widget;
    }

    return this._buildWidget({
      applicationId,
      nodeId,
      widgetsUrl,
      scopeId,
      previewModel,
      type,
    });
  }

  @(task(function* (previewModel, type = 'scheduling') {
    let widget = yield this.initWidget(previewModel, type);
    // NOTE: timeout allows for modal animation
    yield timeout(this.revealAnimationTimeout);
    widget?.reveal();
  }).drop())
  revealTask;

  _appendWidget(widget) {
    document.body.appendChild(widget.node);
  }

  _buildWidget({ applicationId, nodeId, widgetsUrl, scopeId, previewModel, type }) {
    return new Promise(resolve => {
      let node = this._buildNode(nodeId);
      let widget = new window.SPWidget(node);

      let { scopeUri, baseUrl } = this._getUrlParts(widgetsUrl);
      this._setBaseUrl(baseUrl);

      if (!previewModel) {
        this._storeWidget(widget, scopeId);
      }

      widget.init({
        scopeId,
        scopeUri,
        scopeGlobal: true,
        applicationId,
        channel: WEBSITE_WIDGET_CHANNEL,
        appearance: {
          fullScreen: true,
          colorSetId: previewModel?.colorSetId,
          theme: previewModel?.theme,
        },
        ...(type === 'contact' ? { contact: true } : {}),
      });

      if (isEmberTesting()) {
        return resolve(widget);
      }

      widget.iframe.addEventListener('load', () => {
        resolve(widget);
      });

      this._appendWidget(widget);
    });
  }

  _getUrlParts(widgetsUrl) {
    let { host, origin } = new URL(widgetsUrl);
    let scopeUri = host.split('.')[0];
    let baseUrl = origin.replace(`${scopeUri}.`, '');
    return { scopeUri, baseUrl };
  }

  _setBaseUrl(url) {
    if (ENV.railsEnv === 'test' && location.port) {
      url += `:${location.port}`;
    }
    window.SPWidgetBase = url;
  }

  _buildNode(nodeId) {
    let node = document.createElement('div');
    node.id = nodeId;
    return node;
  }

  _storeWidget(widget, scopeId) {
    this.widgetInstances[scopeId] = widget;
  }

  _retrieveWidget(scopeId) {
    return this.widgetInstances[scopeId];
  }
}
