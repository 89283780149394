import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import styles from './messaging-client-root.module.scss';

export default class MessagingClientRoot extends Component {
  @service intl;
  @service store;

  @tracked conversations;

  appName = this.intl.t('app_name');
  styles = styles;

  get navigationText() {
    return 'Secure messages';
  }

  getConversations = task(async () => {
    const result = await this.store.findAll('conversation');
    this.conversations = result;
  });

  constructor(...args) {
    super(...args);
    this.getConversations.perform();
  }
}
