/* import __COLOCATED_TEMPLATE__ from './site-header.hbs'; */
import { action, computed, get, set } from '@ember/object';
import { and, gt, or, reads } from '@ember/object/computed';
import { classNames } from '@ember-decorators/component';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { service } from '@ember/service';
import $ from 'jquery';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './site-header.module.scss';

@classic
@classNames(styles.component)
export default class SiteHeader extends Component {
  @service announcement;
  @service currentPractice;
  @service fastboot;
  @service keyboardNavigation;
  @service media;
  @service router;
  @service session;
  @service store;
  @service prospectiveClient;

  defaultPracticeName = 'Client Portal';

  @reads('session.environment.messagingEmbed') messagingEmbed;
  @reads('session.currentClientAccess') clientAccess;
  @reads('clientAccess.client') currentSignedInClient;
  @reads('session.currentClient') currentClient;
  @reads('session.currentClientOptions') currentClientOptions;
  @or('currentPractice.fullName', 'defaultPracticeName') practiceName;
  @reads('currentSignedInClient.permissions.messaging') messagingPermission;
  @reads('currentClient.permissions.billingDocuments') billingDocumentsPermission;
  @reads('currentClient.permissions.selfScheduling') selfSchedulingPermission;
  @reads('currentClient.permissions.appointments') appointmentsPermission;
  @gt('currentClientOptions.length', 1) hasClientOptions;
  @or('isClientDisplayed', 'currentClient') hasSelectedClient;
  @and('messagingEmbed', 'messagingPermission') hasMessagingIcon;
  @or('hasMessagingIcon', 'displayAnnouncements') displayDivider;
  @reads('announcement.isAnnouncementsActive') displayAnnouncements;
  @reads('currentPractice.featureSecureMessagingEmber') featureSecureMessagingEmber;

  @computed('clientAccess.isOtpPending')
  get hasNotPendingAccess() {
    return this.clientAccess && !this.clientAccess.isOtpPending;
  }

  @computed('hasNotPendingAccess', 'currentSignedInClient', 'currentClient')
  get isClientDisplayed() {
    if (!this.hasNotPendingAccess || !this.currentClient) {
      return false;
    }

    return (
      this.currentClientOptions.length > 1 || this.currentSignedInClient !== this.currentClient
    );
  }

  didInsertElement() {
    super.didInsertElement(...arguments);

    if (this.fastboot.isFastBoot || isEmberTesting()) {
      return;
    }
  }

  @action
  toggleNav(e) {
    let classList = get(e, 'target.classList');
    let isProfileLink = classList && classList.contains('client-profile-btn');
    if (this.media.isSmDown && !isProfileLink) {
      this.toggleProperty('isNavShown');
      this._toggleNav();
    }
  }

  @action
  closeNav() {
    set(this, 'isNavShown', false);
    this._toggleNav();
  }

  @action
  navigateTo(route) {
    this.router.transitionTo(route);
  }

  _toggleNav() {
    let navElement = $('.mobile-nav-wrapper', this.element);

    isEmberTesting() ? navElement.toggle() : navElement.slideToggle();
    this.isNavShown
      ? this.keyboardNavigation.setup([
          '.mobile-nav-wrapper a',
          '.mobile-nav-wrapper button',
          '.menu-btn',
        ])
      : this.keyboardNavigation.teardown();
  }
}
