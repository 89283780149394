/* import __COLOCATED_TEMPLATE__ from './questionnaire-form.hbs'; */
import {
  ARM5,
  ASRS,
  AUDIT,
  AUDITCPLUS,
  CDCHRQOL4,
  CSSRS,
  DASS21,
  DSSB,
  FS,
  GAD7,
  ICG,
  PCL5,
  PEG,
  PHQ15,
  PHQ9,
  SF20,
  SWLS,
  WSAS,
  YBOCS,
} from 'client-portal/models/document-request-questionnaire';
import { action, computed, setProperties } from '@ember/object';
import { bannerError, callbackError } from 'client-portal/utils/error-handling';
import { classNames } from '@ember-decorators/component';
import { ensureSafeComponent } from '@embroider/util';
import { filterBy, reads } from '@ember/object/computed';
import { importSync } from '@embroider/macros';
import { modifier } from 'ember-modifier';
import { resetErrors } from 'client-portal/utils/validate-record';
import { service } from '@ember/service';
import { waitForRender } from 'ember-simplepractice/utils/waiters';
import Super from './autosave-form';
import classic from 'ember-classic-decorator';
import styles from './questionnaire-form.module.scss';
import stylesSuper from './contact-info-form.module.scss';

const MEASUREMENT_FEATURE_FLAGS_MAP = {
  [ARM5]: 'isMeasurementArm5Enabled',
  [ASRS]: 'isMeasurementAsrsEnabled',
  [AUDITCPLUS]: 'isMeasurementAuditcplusEnabled',
  [CDCHRQOL4]: 'isMeasurementCdchrqol4Enabled',
  [CSSRS]: 'isMeasurementCssrsEnabled',
  [DASS21]: 'isMeasurementDass21Enabled',
  [YBOCS]: 'isMeasurementYbocsEnabled',
};

@classic
@classNames(styles.component)
export default class SiteDocumentsQuestionnaireForm extends Super {
  @service session;
  @service mixpanel;
  @service currentPractice;
  styles = styles;

  stylesSuper = stylesSuper;
  showModal = false;
  inputElements = [];

  @reads('model') autosaveRecord;
  @reads('model.documentTitle') documentTitle;
  @filterBy(
    'templateQuestionsAndAnswers',
    'question',
    '9. Thoughts that you would be better off dead or of hurting yourself in some way.'
  )
  questionNine;
  @reads('questionNine.firstObject.answer.answers.firstObject.answer_text') questionNineAnswer;

  onModalSetupAndTeardown = modifier(
    () => {
      let footer = document.querySelector('footer');
      footer?.classList?.add('invisible');

      return () => {
        footer?.classList?.remove('invisible');
      };
    },
    { eager: false }
  );

  setupSingleSelectInput = modifier(
    (element, [question]) => {
      if (
        question.questionType === 'SINGLE_SELECT' &&
        this.isMeasure &&
        this.documentTitle === CSSRS
      ) {
        let inputElements = element.querySelectorAll('input');
        this.inputElements.push({ questionId: question.id, inputElements });

        let questionTwoQuestionAndAnswer = this.templateQuestionsAndAnswers.findBy('id', 3);
        let selectedNo = questionTwoQuestionAndAnswer.answer.answers[0]?.answer_id === '2';

        if (selectedNo && question.id !== 3) {
          this.onSingleSelectChange(questionTwoQuestionAndAnswer);
        }
      }
    },
    { eager: false }
  );

  @computed('model.isReviewing', 'isMeasure', 'questionNineAnswer')
  get canShowModal() {
    return (
      this.isMeasure &&
      this.documentTitle === PHQ9 &&
      this.model.isReviewing &&
      !this.questionNineAnswer?.includes('Not at all')
    );
  }

  @computed(
    'model.{templateQuestionsAndAnswers.@each.required,userAnswersWithoutScores,isReviewing}',
    'isMeasure'
  )
  get templateQuestionsAndAnswers() {
    let { isReviewing, templateQuestionsAndAnswers, userAnswersWithoutScores } = this.model;
    let questionsAndAnswers = templateQuestionsAndAnswers.map(x => {
      let inputModule = importSync(
        `ember-simplepractice/components/shared/questionnaire/${x.normalizedType}-input`
      );
      x.inputComponent = ensureSafeComponent(inputModule.default, this);

      let reviewModule;
      if (['date', 'signature'].includes(x.normalizedType)) {
        reviewModule = importSync(`../../shared/questionnaire/${x.normalizedType}-review`);
      } else {
        reviewModule = importSync(
          `ember-simplepractice/components/shared/questionnaire/${x.normalizedType}-review`
        );
      }

      x.reviewComponent = ensureSafeComponent(reviewModule.default, this);

      return x;
    });

    if (this.isMeasure && isReviewing) {
      return questionsAndAnswers.map(x => {
        let answer = userAnswersWithoutScores.findBy('questionId', x.id) || x.answer;
        return { ...x, answer };
      });
    } else {
      return questionsAndAnswers;
    }
  }

  @action
  additionalClassNames(index) {
    if (!this.isMeasure) {
      return '';
    }

    let isDecoratableFirstQuestion =
      index === 0 && [ARM5, DSSB, GAD7, PHQ9, PHQ15].includes(this.documentTitle);
    let isDecoratableThirdQuestion =
      index === 2 && [PCL5, WSAS, SWLS, FS].includes(this.documentTitle);
    let isDecoratableSf20Question = [0, 2, 12, 19].includes(index) && this.documentTitle === SF20;
    if (isDecoratableFirstQuestion || isDecoratableThirdQuestion || isDecoratableSf20Question) {
      return 'measurement-title-input';
    }

    let isAsrsPartName = [0, 7].includes(index) && this.documentTitle === ASRS;
    if (isAsrsPartName) {
      return 'measurement-part-name';
    }

    let isCssrsTitle = [0, 3].includes(index) && this.documentTitle === CSSRS;
    if (isCssrsTitle) {
      return 'measurement-neutral-title';
    }
  }

  @computed(
    'model.mixpanelType',
    ...Object.values(MEASUREMENT_FEATURE_FLAGS_MAP).map(
      featureFlag => `currentPractice.${featureFlag}`
    )
  )
  get isMeasure() {
    if (
      [GAD7, PHQ9, AUDIT, DSSB, FS, ICG, PCL5, PEG, PHQ15, SF20, SWLS, WSAS].includes(
        this.documentTitle
      )
    ) {
      return this.model.mixpanelType === 'measure';
    }

    let featureFlag = MEASUREMENT_FEATURE_FLAGS_MAP[this.documentTitle];
    return (
      this.model.mixpanelType === 'measure' && featureFlag && this.currentPractice.get(featureFlag)
    );
  }

  @action
  async nextStep() {
    try {
      resetErrors(this.model);
      await this.completeRequestTask.perform(this.model);
      if (!(this.isDestroyed || this.isDestroying)) {
        this.set('autosaveData', {});
      }
    } catch (err) {
      callbackError(err, message => {
        if (message.status !== '422') {
          bannerError(err, { title: `${this.model.documentTitle} could not be submitted` });
        }
        return true;
      });

      await waitForRender();
      this.showValidationError();
    }
  }

  @action
  scrollToTop() {
    if (!this.model.isReview) {
      document.body.scrollTop = 0;
    }
  }

  @action
  editSignature(question) {
    this.setProperties({
      title: 'Edit your signature',
      showSignatureModal: question.id,
    });
  }

  @action
  sign(question) {
    if (this.session.signatoryName) {
      setProperties(question.answer, {
        isDirty: true,
        isSigned: true,
      });
    } else {
      this.setProperties({
        title: 'Create your signature',
        showSignatureModal: question.id,
      });
    }
  }

  @action
  onClose() {
    this.session.currentClient.rollbackAttributes();
    this.session.currentClientAccess.client.rollbackAttributes();
    this.set('showSignatureModal', undefined);
  }

  @action
  onSingleSelectChange(question) {
    if (this.isMeasure && this.documentTitle === CSSRS) {
      let answer = question.answer;
      let skippableQuestionIds = [5, 6, 7];
      let skipQuestionsCondition = { questionId: 3, answerId: '2' };

      if (answer.questionId === skipQuestionsCondition.questionId) {
        let inputElements = this.inputElements
          .filter(x => skippableQuestionIds.includes(x.questionId))
          .reduce((arr, inputElement) => arr.concat(...inputElement.inputElements), []);

        if (answer.answers[0].answer_id === skipQuestionsCondition.answerId) {
          inputElements.forEach(x => setProperties(x, { disabled: true, checked: false }));
          this.setRequiredQuestionsAndAnswers(skippableQuestionIds, false);
          this.model.userAnswers
            .filter(userAnswer => skippableQuestionIds.includes(userAnswer.questionId))
            .forEach(x => {
              setProperties(x, { answers: [] });
            });
        } else {
          inputElements.forEach(x => setProperties(x, { disabled: false }));
          this.setRequiredQuestionsAndAnswers(skippableQuestionIds, true);
        }
      }
    }
  }

  setRequiredQuestionsAndAnswers(questionIds, required) {
    this.model.templateQuestionsAndAnswers
      .filter(x => questionIds.includes(x.id))
      .forEach(question => {
        setProperties(question, { required });
      });
  }
}
