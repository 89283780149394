import { and, equal, reads } from '@ember/object/computed';
import { computed } from '@ember/object';
import { A as emberA } from '@ember/array';
import {
  isAndroidMinNativeDocVersion,
  isIOSMinNativeDocVersion,
  isMobileApp,
} from 'client-portal/utils/mobile-detect';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import classic from 'ember-classic-decorator';

@classic
export default class PracticeModel extends Model {
  @belongsTo('office', { async: false }) defaultOffice;
  @belongsTo('office', { async: false }) defaultMarketingOffice;
  @belongsTo('phone', { async: false }) defaultPhone;
  @belongsTo('practice-website', { async: false }) practiceWebsite;
  @hasMany('clinician', { async: false }) clinicians;
  @attr() fullName;
  @attr() practiceUrl;
  @attr() schedulingWidgetUrl;
  @attr() schedulingWidgetScopeId;
  @attr() schedulingWidgetApplicationId;
  @attr() mixpanelKey;
  @attr() customStripePublishableKey;
  @attr() currency;
  @attr('boolean') hasCustomDomain;
  @attr('boolean') clientPortalEnabled;
  @attr('boolean') selfSchedulingEnabled;
  @attr('boolean') isNewClientAllowedToBookAppt;
  @attr('boolean') isNewContactsAllowedToBookAppt;
  @attr('boolean') isNewCouplesAllowedToBookAppt;
  @attr('boolean') isNewIndividualClientsAllowedToBookAppt;
  @attr('boolean') requireCreditCardForNewClients;
  @attr('boolean') documentsUpload;
  @attr('number') selfScheduleMinimumLeadTime;
  @attr('boolean') hasCustomStripeAccount;
  @attr() stripeConnectedAccountId;
  @attr('boolean') isClientAllowedToConfirmAppt;
  @attr('boolean') isClientAllowedToCancelAppt;
  @attr() timeZone;
  @attr('number') clientCancellableHrs;
  @attr('boolean') websiteEnabled;
  @attr('boolean') websitePublished;
  @attr('boolean') telehealthEnabled;
  @attr() recaptchaSiteKey;
  @attr('string') featureBookingWidgetMedicationQuestion;
  @attr('boolean') featureClientFeedbackBanner;
  @attr('boolean') featureClientappDocumentAccess;
  @attr('boolean') featureClientappAndroidDocuments;
  @attr('boolean') featureClientappDocumentsUpdate;
  @attr('boolean') featureExpandPrescreenerQuestions;
  @attr('boolean') featureAndroidClientappPromotionBanner;
  @attr('boolean') featureIosClientappPromotionBanner;
  @attr('boolean') isMeasurementArm5Enabled;
  @attr('boolean') isMeasurementAsrsEnabled;
  @attr('boolean') isMeasurementAuditcplusEnabled;
  @attr('boolean') isMeasurementCdchrqol4Enabled;
  @attr('boolean') isMeasurementCssrsEnabled;
  @attr('boolean') isMeasurementDass21Enabled;
  @attr('boolean') isMeasurementYbocsEnabled;
  @attr('boolean') featureClientPortalLegalAgreements;
  @attr('boolean') featureClientPortalInsuranceEffectiveDates;
  @attr('boolean') featureInsuranceTextRecognition;
  @attr('boolean') featurePracticeContactForm;
  @attr('boolean') featureProspectiveClient;
  @attr('boolean') featureAddressAutocompleteSimplified;
  @attr('boolean') hasOonClaimFiling;
  @attr() featureStickyCtaBookingWidget;
  @attr('string') featureWidgetPaymentMethod;
  @attr('string') featureWidgetPaymentMethodDesktop;
  @attr('string') featureWidgetPaymentMethodGaRollout;
  @attr('boolean') announcementsAvailable;
  @attr('boolean') featureEnableClientPortalPrescreenerSettings;
  @attr('boolean') showPrescreenerSection;
  @attr('boolean') showPaymentSelection;
  @attr('boolean') featureClientTimezone;
  @attr('boolean') featureSecureMessagingEmber;

  @and('clientPortalEnabled', 'selfSchedulingEnabled') effectiveSelfScheduling;
  @and('effectiveSelfScheduling', 'isNewClientAllowedToBookAppt') requestAsNewClient;
  @reads('defaultPhone.number') defaultPhoneNumber;
  @equal('featureStickyCtaBookingWidget', 'test') featureStickyCtaBookingWidgetEnabled;

  @computed('defaultPhoneNumber')
  get cleanPhoneNumber() {
    let { defaultPhoneNumber } = this;
    return defaultPhoneNumber ? defaultPhoneNumber.replace(/[^\d+]+/g, '') : defaultPhoneNumber;
  }

  @computed(
    'isNewContactsAllowedToBookAppt',
    'isNewCouplesAllowedToBookAppt',
    'isNewIndividualClientsAllowedToBookAppt'
  )
  get clientTypesAllowedToBookAppt() {
    return [
      (this.isNewIndividualClientsAllowedToBookAppt && 'client') || null,
      (this.isNewCouplesAllowedToBookAppt && 'couple') || null,
      (this.isNewContactsAllowedToBookAppt && 'contact') || null,
    ].compact();
  }

  @computed('featureClientappDocumentsUpdate', 'featureClientappAndroidDocuments')
  get canUseNativeDocRendering() {
    return (
      (this.featureClientappDocumentsUpdate && isIOSMinNativeDocVersion()) ||
      (this.featureClientappAndroidDocuments && isAndroidMinNativeDocVersion())
    );
  }

  @computed('featureClientappDocumentAccess')
  get hasClientappDocumentAccess() {
    return this.featureClientappDocumentAccess && isMobileApp();
  }

  static fieldsFor(_context) {
    let fields = emberA();
    this.fields.forEach((_, v) => fields.pushObject(v));
    fields.removeObject('meta');
    fields.removeObjects(['featureBookingWidgetDisplaySpecialties']);

    return fields;
  }

  @computed('featureBookingWidgetMedicationQuestion')
  get featureBookingWidgetMedicationQuestionEnabled() {
    return this.featureBookingWidgetMedicationQuestion === 'test';
  }

  @computed('featureWidgetPaymentMethod')
  get featureWidgetPaymentMethodEnabled() {
    return this.featureWidgetPaymentMethod === 'test';
  }

  @computed('featureWidgetPaymentMethodDesktop')
  get featureWidgetPaymentMethodDesktopEnabled() {
    return this.featureWidgetPaymentMethodDesktop === 'test';
  }

  @computed('featureWidgetPaymentMethodGaRollout')
  get featureWidgetPaymentMethodGaRolloutEnabled() {
    return this.featureWidgetPaymentMethodGaRollout === 'test';
  }
}
